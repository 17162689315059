import { Feature, MultiPolygon, Polygon, Properties } from "@turf/turf";
import { LatLngBounds } from "leaflet";

import { ImagerySource, VectorLayer, VectorLayers } from "../../types/responses";

export enum OVERLAY_LAYERS {
  CLEARANCE = "Clearance",
  REGIONS = "Regions",
  FEEDERS = "Circuits",
  ENCROACHMENTS = "Infringements",
  ROADS = "Roads",
  URBAN_AREAS = "Urban Areas",
}

export enum LAYER_SWITCH_CONTROL {
  LAYERS = "LAYERS",
  IMAGERY_SOURCES = "IMAGERY_SOURCES",
  ADDITIONAL_OVERLAYS = "ADDITIONAL_OVERLAYS",
  ADDITIONAL_LAYERS = "ADDITIONAL_LAYERS",
}

export type SwitchLayerControl = {
  showSwitch: boolean;
  checked?: boolean;
};

export enum ADDITIONAL_LAYER_TYPE {
  BATS = "long-eared-bats",
  CLOSED_SYSTEM_ROADS = "closed-system-roads",
  CONSERVATION_LANDS = "conservation-lands",
  CREES_FLOWER = "glade-cress-flower",
  HAZARD_TREE = "hazard-trees",
  NATIONAL_FOREST = "national-forests",
  NON_SYSTEM_ROADS = "non-system-access-roads",
  NO_SPRAY = "no-spray-areas",
  ORCHIDS = "white-fringeless-orchids",
  WETLANDS = "wetlands",
  HYDROLOGY = "hydrology",
  URBAN = "urban-areas",
  WILDFIRE_CLUSTERS = "wildfire-clusters",
  FEE_PARCELS = "fee-parcels",
  BUILDINGS = "buildings",
  CONSERVATION_EASMENT = "conservation-easment",
  LANDSAT_FIRE = "landsat-fire",
}

export enum ADDITIONAL_LAYER_CATEGORY {
  ROADS = "ROADS",
  SENSITIVE_SPECIES = "SENSITIVE_SPECIES",
  DEMOGRAPHIC = "DEMOGRAPHIC",
  LANDS = "LANDS",
  HYDROLOGY = "HYDROLOGY",
  Environmental = "Environmental",
  FEE_PARCELS = "Other",
  CONSERVATION_EASMENT = "conservation-easment",
  BUILDINGS = "Buildings",
  LANDSAT_FIRE = "landsat-fire",
}

export const layerCategories: Record<ADDITIONAL_LAYER_TYPE, ADDITIONAL_LAYER_CATEGORY> = {
  "hazard-trees": ADDITIONAL_LAYER_CATEGORY.DEMOGRAPHIC,
  wetlands: ADDITIONAL_LAYER_CATEGORY.DEMOGRAPHIC,
  "long-eared-bats": ADDITIONAL_LAYER_CATEGORY.SENSITIVE_SPECIES,
  "no-spray-areas": ADDITIONAL_LAYER_CATEGORY.DEMOGRAPHIC,
  "conservation-lands": ADDITIONAL_LAYER_CATEGORY.LANDS,
  "national-forests": ADDITIONAL_LAYER_CATEGORY.LANDS,
  "white-fringeless-orchids": ADDITIONAL_LAYER_CATEGORY.SENSITIVE_SPECIES,
  "glade-cress-flower": ADDITIONAL_LAYER_CATEGORY.SENSITIVE_SPECIES,
  "closed-system-roads": ADDITIONAL_LAYER_CATEGORY.ROADS,
  "non-system-access-roads": ADDITIONAL_LAYER_CATEGORY.ROADS,
  "urban-areas": ADDITIONAL_LAYER_CATEGORY.DEMOGRAPHIC,
  hydrology: ADDITIONAL_LAYER_CATEGORY.HYDROLOGY,
  "wildfire-clusters": ADDITIONAL_LAYER_CATEGORY.Environmental,
  buildings: ADDITIONAL_LAYER_CATEGORY.BUILDINGS,
  "fee-parcels": ADDITIONAL_LAYER_CATEGORY.FEE_PARCELS,
  "conservation-easment": ADDITIONAL_LAYER_CATEGORY.CONSERVATION_EASMENT,
  "landsat-fire": ADDITIONAL_LAYER_CATEGORY.LANDSAT_FIRE,
};

type CashedMasks = Nullable<Record<string, Feature<Polygon | MultiPolygon, Properties>>>;

export type AdditionalOverlay = {
  data: { name: string; checked: boolean; key: string; additionalInfo?: string; image?: string };
  params: {
    useCors: boolean;
    layers?: number[];
    renderingRule?: {
      rasterFunction?: string;
    };
  };
  url: string;
  opacityValue: number;
  layerType: "IMAGE_MAP" | "DYNAMIC_MAP" | "TILE_MAP";
  isDefault?: boolean;
  popupName: string;
};

export interface AdditionalLayer extends VectorLayer {
  checked: boolean;
}

export type MapStoreState = {
  defaultBounds?: Nullable<L.LatLngBoundsExpression>;
  defaultCenter?: Nullable<L.LatLngExpression>;
  map: Nullable<L.Map>;
  defaultZoomLevel: number;
  currentZoomLevel: number;
  baseLayer: string;
  zoomControlStatus: boolean;
  imagerySources: Record<string, ImagerySource>;
  selectedImagerySourceId: Nullable<string>;
  overlayMapLayers: Record<OVERLAY_LAYERS, boolean>;
  layerControlVisible: boolean;
  overlaySwitchControl: Record<LAYER_SWITCH_CONTROL, SwitchLayerControl>;
  selectedAdditionalOverlay: Nullable<string>;
  additionalOverlayList: Nullable<Record<string, AdditionalOverlay>>;
  additionalOverlayOpacity: Record<string, number>;
  hoveredObjectId: Nullable<string>;
  currentMapBounds: Nullable<LatLngBounds>;
  vectorLayers: Nullable<Record<string, AdditionalLayer>>;
  urbanLayerId: Nullable<string>;
  mapOpacityLayerControl: Nullable<{ opacity: number; checked: boolean }>;
  toggleLayerControl: (forceClose?: boolean) => void;
  setMap: (data: L.Map) => void;
  invalidateMapSize: () => void;
  initMapHandlers: () => void;
  setZoomControlStatus: (state: boolean) => void;
  setImagerySourcesData: (imagerySources: Nullable<Record<string, ImagerySource>>) => void;
  setSelectedImagerySource: (id: string) => void;
  setOverlayMapLayerVisibility: ({ name, key, value }: { name: string; key: string; value: boolean | string }) => void;
  masksForPolygons: CashedMasks;
  closeMapPopup: () => void;
  setOverlaySwitchState: ({ name, value }: { name: string; value: boolean }) => void;
  setAdditionalOverlay: (id: string) => void;
  setOpacityOverlayByKey: (key: string, value: number) => void;
  setAdditionalOverlays: (data: Record<string, AdditionalOverlay>) => void;
  setHoveredObjectId: (id: Nullable<string>) => void;
  setBaseLayerName: (name: string) => void;
  setCurrentMapBounds: (bounds: Nullable<LatLngBounds>) => void;
  setVectorLayerVisibility: ({ name, key, value }: { name: string; key: string; value: boolean | string }) => void;
  setVectorLayers: (layers: Nullable<VectorLayers>) => void;
  setLatestObjectIdSelectedForMapAnimation: (id: string) => void;
  latestObjectIdSelectedForMapAnimation: Nullable<string>;
  latestObjectIdSelectedForMapAnimationTimestamp: Nullable<number>;
  getLatestObjectSelectedForMapAnimation: () => {
    id: Nullable<string>;
    timestamp: Nullable<number>;
  };
  setMapLayerOpacity: (opacity: number) => void;
  toggleOpacityMapLayer: () => void;
  resetMapState: () => void;
};
